jQuery(document).ready(function ($) {
  "use strict";

  // Adding classes to the standlone page, this should be remove it later
  $(".cleanpage")
    .find(".ninja-forms-form-wrap")
    .addClass("consultation-form-001__right-col");
  $(".cleanpage").find(".ninja-forms-form-wrap").addClass("col-md-12");
  $(".cleanpage")
    .find(".ninja-forms-form-wrap")
    .addClass("consultation-form-001");
  $(".cleanpage").find(".nf-form-fields-required").remove();

  const urlParams = new URLSearchParams(window.location.search);

  // Gclid Id as localstorage variable.
  if (urlParams) {
    if (urlParams.get("gclid")) {
      const gclid = urlParams.get("gclid");
      const cookieExists = document.cookie
        .split(";")
        .some((item) => item.trim().startsWith("gclid="));
      const gclidCleanup = gclid.replaceAll('"', "");
      localStorage.setItem("gclidVal", JSON.stringify(gclidCleanup));
    }
  }

  //Calling this function to rewrite ninjaforms treatments/conditions
  updateNinjaFields();

  // Call updateNinjaFields when clinic or treatments changes.
  $(document).on("change", "select", function () {
    updateNinjaFields();
  });

  let isExecuted = false;

  /**
   *  Get the treatments, clinics and
   *  conditions from the custom tables.
   *  To show/hide dom elements.
   */
  function updateNinjaFields() {
    var $result = $.ajax({
      type: "POST",
      url: "/wp-content/themes/skin/getConditionsEnquiryForm.php",
      data: $(this).serialize(),
      success: myCallback,
    });

    //Defining all arrays and variables.
    var $clinic = [];
    var $treatment = [];
    var $find = "";
    var $findTreatment = "";
    var $groups = [];
    var $tgroup = [];

    //Ajax callback.
    function myCallback(response) {
      var $group = "";

      //Setup gclid value as hidden field.
        if (localStorage.getItem("gclidVal")) {
        
        for (var i = dataLayer.length - 1; i >= 0; i--) {
          // Check if the current item in the data layer has the OnetrustActiveGroups key
          if (dataLayer[i].hasOwnProperty('OnetrustActiveGroups')) {
            // Get the value of the OnetrustActiveGroups key
            var onetrustActiveGroups = dataLayer[i]['OnetrustActiveGroups'];
            // Check if the value "C0004" exists within the OnetrustActiveGroups value
            if (onetrustActiveGroups && onetrustActiveGroups.includes('C0004')) {
              $("#nf-field-114").val(localStorage.getItem("gclidVal"));
            } 
            break;
          }
        }
      }
      const obj = JSON.parse(response);
      for (let i = 0; i < obj.length; i++) {
        for (let j = 0; j < obj[0].length; j++) {
          var $hideThis = 0;
          $clinic[j] = obj[i][j].clinic;
          if (j > 0) {
            if (obj[i][j].treatment != obj[i][j - 1].treatment) {
              $treatment[j] = obj[i][j].treatment;
              $groups[j] = obj[i][j].tgroup;
            }
          } else if (j == 0) {
            $treatment[j] = obj[i][j].treatment;
            $groups[j] = obj[i][j].tgroup;
          }
        }
      }

      // remove all clinics that are not in the array.
      $("#nf-field-8  > option").each(function (i) {
        $find = $(this).text();

        if (jQuery.inArray($find, $clinic) === -1 && i > 0) {
          $(this).remove();
        }
        $(this).val();
      });

      if (!isExecuted) {
        // remove all treatments that are not in the array.
        $("#nf-field-9  > option").each(function (x) {
          $findTreatment = $(this).text();
          if (jQuery.inArray($findTreatment, $treatment) === -1 && x > 0) {
            $(this).remove();
          }
        });
        rewriteTreatments($treatment, $groups, $group);
        isExecuted = true; // Set the flag to indicate that the code has been executed
      }

      // Show/Hide treatments when selecting a clinic.
      if ($("#nf-field-8").val() != "") {
        var $treatmentFixed = "";
        for (let p = 0; p < obj.length; p++) {
          for (let q = 0; q < obj[0].length; q++) {
            if ($("#nf-field-8 option:selected").text() === obj[p][q].clinic) {
              $treatment = obj[p][q].treatment.toLowerCase();
              $treatmentFixed = $treatment.replaceAll(" ", "-");

              if (obj[p][q].show == 0) {
                $('#nf-field-9 option[value="' + $treatmentFixed + '"]')
                  .filter(":not(span > option)")
                  .wrap("<span>")
                  .parent()
                  .hide();
              } else if (obj[p][q].show == 1) {
                $('#nf-field-9 option[value="' + $treatmentFixed + '"]')
                  .filter("span > option")
                  .unwrap();
              }
            }
          }
        }

        isHiddenGroup($("#nf-field-9").parent());
      } else {
        $("#nf-field-9 span").find("optgroup").unwrap();
        $("#nf-field-9 option").filter("span > option").unwrap();
      }

      // Show/Hide clinics when selecting a clinic.
      if ($("#nf-field-9").val() != "") {
        for (let p = 0; p < obj.length; p++) {
          for (let q = 0; q < obj[0].length; q++) {
            if (
              $("#nf-field-9 option:selected").text() === obj[p][q].treatment
            ) {
              if (obj[p][q].show == 0) {
                $("#nf-field-8 option:contains(" + obj[p][q].clinic + ")")
                  .filter(":not(span > option)")
                  .wrap("<span>")
                  .parent()
                  .hide();
              } else {
                $("#nf-field-8 option:contains(" + obj[p][q].clinic + ")")
                  .filter("span > option")
                  .unwrap();
              }
            }
          }
        }
      } else {
        $("#nf-field-8 option").filter("span > option").unwrap();
      }
    }
  }

  /**
   * Hide optgroup if it doesn't have children.
   * @param {selector} element
   */
  function isHiddenGroup(element) {
    const $selectField = element;

    $selectField.find("optgroup").each(function () {
      const $optgroup = $(this);
      const $children = $optgroup.children();
      let allChildrenHidden = true;

      $children.each(function () {
        if (!$(this).is("span")) {
          allChildrenHidden = false;
          return false; // Exit the loop if a visible child is found
        }
      });

      if (allChildrenHidden) {
        // Check if the optgroup is already wrapped within a span
        if (!$optgroup.parent().is("span")) {
          $optgroup.wrap("<span>").parent().hide();
        }
      } else {
        // Check if the optgroup is wrapped within a span
        if ($optgroup.parent().is("span")) {
          $optgroup.unwrap();
        }
      }
    });
  }

  /**
   *
   * @param {array treatment} $treatment
   * @param {array optgroups} $groups
   * @param {string group label} $group
   * @returns
   */

  function rewriteTreatments($treatment, $groups, $group) {
    var $treatmentFixed = "";
    const filteredObjTre = Object.values($treatment).reduce((acc, value) => {
      if (value !== undefined && value !== null && value !== "") {
        acc.push(value);
      }
      return acc;
    }, []);

    const filteredObjGr = Object.values($groups).reduce((acc, value) => {
      if (value !== undefined && value !== null && value !== "") {
        acc.push(value);
      }
      return acc;
    }, []);

    const $selectField = $("#nf-field-9");
    const selectedValue = $selectField.val(); // Store the currently selected value

    $selectField.find("option:gt(0)").remove();

    // Group treatments by their corresponding groups
    const groupedTreatments = {};
    for (let p = 0; p < filteredObjTre.length; p++) {
      const treatment = filteredObjTre[p];
      const group = filteredObjGr[p];

      if (!groupedTreatments[group]) {
        groupedTreatments[group] = [];
      }
      groupedTreatments[group].push(treatment);
    }

    // Print optgroups with their respective children
    for (const [group, treatments] of Object.entries(groupedTreatments)) {
      const $optgroup = $("<optgroup></optgroup>").attr("label", group);
      for (const treatment of treatments) {
        const $treatment1 = treatment.toLowerCase();
        $treatmentFixed = $treatment1.replaceAll(" ", "-");
        const $element = $("<option></option>")
          .val($treatmentFixed)
          .text(treatment);
        $optgroup.append($element);
      }
      $selectField.append($optgroup);
    }

    $selectField.val(selectedValue); // Restore the previously selected value
    return 1;
  }

  var ninjaFormsValidation = Marionette.Object.extend({
    initialize: function () {
      // On the Form Submission's field validaiton...
      var submit = Backbone.Radio.channel("submit");
      this.listenTo(submit, "validate:field", this.validate);

      // on the Field's model value change...
      var fieldsChannel = Backbone.Radio.channel("fields");
      this.listenTo(fieldsChannel, "change:modelValue", this.validate);
      this.listenTo(fieldsChannel, "add:error", this.handleError);
      this.listenTo(fieldsChannel, "remove:error", this.handleValid);
    },

    invalidHTML: function () {
      return '<svg xmlns="http://www.w3.org/2000/svg" class="crossmark" viewBox="0 0 52 52"><circle cx="26" cy="26" r="25" fill="none" class="crossmark__circle"/><path class="crossmark__cross" fill="#4a90d6" stroke="#fff" stroke-linejoin="round" d="M17.214 35.257l18.445-18.445M16.91 16.66l18.75 18.748"/></svg>';
    },

    validHTML: function () {
      return '<svg xmlns="http://www.w3.org/2000/svg" class="checkmark" viewBox="0 0 52 52"><circle cx="26" cy="26" r="25" fill="none" class="checkmark__circle"/><path fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" class="checkmark__check"/></svg>';
    },

    handleError: function (model) {
      var modelId = model.id,
        container = jQuery("#nf-field-" + modelId).parent(".nf-field-element");

      // Remove check if any
      container.find("svg.checkmark").remove();

      // Add crossmark
      if (!container.find("svg.crossmark").length) {
        container.append(this.invalidHTML);
      }
    },

    handleValid: function (model) {
      var modelId = model.id,
        container = jQuery("#nf-field-" + modelId).parent(".nf-field-element");

      // Remove check if any
      container.find("svg.crossmark").remove();

      // Add crossmark
      if (!container.find("svg.checkmark").length) {
        container.append(this.validHTML);
      }
    },

    validate: function (model) {
      var type = model.get("type");
      var value = model.get("value");
      var val_length = value.length;
      var modelID = model.get("id");
      var errorID = "custom-field-error";
      var fieldsChannel = Backbone.Radio.channel("fields");

      if (type === "phone") {
        //Add an extra validation. Telephone number should not start with: 076
        if (val_length < 10) {
          var errorMessage = "Telephone number is not valid";
          // Add Error
          fieldsChannel.request("add:error", modelID, errorID, errorMessage);
        } else if (val_length > 11) {
          //Change this error message: Telephone number is not valid.
          var errorMessage = "Telephone number is not valid";
          fieldsChannel.request("add:error", modelID, errorID, errorMessage);
        } else if (!checkprefix(value)) {
          var errorMessage = "Telephone number is not valid";
          fieldsChannel.request("add:error", modelID, errorID, errorMessage);
        } else if (!$.isNumeric(value)) {
          var errorMessage = "Telephone number is not valid";
          fieldsChannel.request("add:error", modelID, errorID, errorMessage);
        }  else if ("phone" === type  && !model.attributes.errors.length) {
          this.handleValid(model);
        } else {
          fieldsChannel.request("remove:error", modelID, errorID);
        }
      } else if (type === "firstname" || type === "lastname") {
        if (val_length > 30) {
          var errorMessage = "Too many characters for this field";
          // Add Error
          fieldsChannel.request("add:error", modelID, errorID, errorMessage);
        }
        else if (
          (type === "firstname" || type === "lastname") && !model.attributes.errors.length) {
          this.handleValid(model);
        } else {
          fieldsChannel.request("remove:error", modelID, errorID);
        }
      }
      
    },
  });


  function checkprefix(A) {
    var s1 = A.toString();
    if (s1.startsWith("076") || s1.startsWith("070")) {
      return false;
    } else if (
      s1.startsWith("01") ||
      s1.startsWith("02") ||
      s1.startsWith("07")
    ) {
      return true;
    } else {
      return false;
    }
  }

  new ninjaFormsValidation();
});
