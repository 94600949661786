jQuery(document).ready(function ($) {
    "use strict";

    // Use our delay function to lazy load the background video
    delayFunction(function(){
        // Define a video element variable for future use, initialise video
        var player = new Plyr(document.querySelectorAll('[data-video]'), {
            "autoplay": true,
            "clickToPlay": false
        });

        // // Check we have a video element, as this is removed using Mobble on mobile
        if ( $('[data-video-container]').length ) {
            // Set autoplay to true
            player.autoplay = 1;
            // Mute the player
            player.volume = 0;

            // Allow a five second delay before fading in the vieo container,
            // To take precedence over the background image placeholder
            $('[data-video-container]').delay(1000).fadeIn(500);

            player.on('ready', function(event) {
                player.play();
            })

            // On click of the popup video link, prevent default
            $('[data-video-popup]').on('click', function(e){
                e.preventDefault();
                // Pause the background video
                player.pause();
            });
        }

        // Set the callback for when the pop video window is closed to resume
        // playing the background video
        $('[data-video-popup]').magnificPopup({
            callbacks: {
                close: function(){
                    if ( $('[data-video-container]').length ) {
                        player.play();
                    }
                }
            }
        });
    });
});
