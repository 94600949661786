(function () {
    "use strict";

    /**
     * Create global function
     * @return jQuery Object    Element (chaining)
     */
    window.delayFunction = function (func, timer) {
        // Default timeout to 20 seconds
        if ( typeof timer === undefined ) {
            timer = 20000;
        }

        // Function not yet called and no timer ID
        var called = false;
        var timer_id = false;

        // Create our new function that clears prior events and timers too
        var new_func = (function(){
            if ( ! called ) {
                called = true;
                return func();
            }

            if ( timer_id ) {
                window.clearInterval(timer_id);
                timer_id = false;
            }

            jQuery(window).off('scroll click focusin keypress mousemove', new_func);
            return;
        });

        jQuery(window).on('load', function() {
            // Set event handlers
            jQuery(window).on('scroll click focusin keypress mousemove', new_func);

            // Set timeout
            if( timer ) {
                timer_id = window.setTimeout(new_func, timer);
            }
        });
    };

}());
