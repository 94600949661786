jQuery(document).on('nfFormReady', function() {

    // If we have a hidden field for the treatment to pre-select from our form, proceed
    if ( jQuery('[data-selected-treatment]').length ) {
        // Fetch the slug from the hidden field value
        var selected_treatment = jQuery('[data-selected-treatment]').val();
        // And also retrieve the form ID we need to apply this value to
        var treatment_form_id = jQuery('[data-selected-treatment]').data('selected-treatment');

        // If we have a default value and a form ID to apply this to, proceed
        if ( selected_treatment && treatment_form_id ) {
            // Set the default value to our provided value and refresh the field to trigger
            // Ninja Forms' conditional logic
            jQuery( treatment_form_id ).val( selected_treatment ).trigger( 'change' );
        }
    }

    // If we have a hidden field for the clinic to pre-select from our form, proceed
    if ( jQuery('[data-selected-clinic]').length ) {
        // Fetch the clinic ID from the hidden field value
        var selected_clinic = jQuery('[data-selected-clinic]').val();
        // And also retrieve the form ID we need to apply this value to
        var clinic_form_id = jQuery('[data-selected-clinic]').data('selected-clinic');

        // If we have a default value and a form ID to apply this to, proceed
        if ( selected_clinic && clinic_form_id ) {
            // Set the default value to our provided value and refresh the field to trigger
            // Ninja Forms' conditional logic
            jQuery( clinic_form_id ).val( selected_clinic ).trigger( 'change' );
        }
    }

    // On form submission, trigger a Google datalayer push for SEO tracking of forms
    nfRadio.channel('forms').on('submit:response', function(form) {
        if ( '1' == form.data.form_id ) {

            var new_user = 0;

            if ( 'true' == form.data.fields_by_key.existing_user_1556141201713.value ) {
                new_user = 1;
            }

            var treatment_name = form.data.fields_by_key.choose_a_treatment_1552307917186.value;

            if ( treatment_name ) {
                treatment_name = treatment_name.replace(/\-/g, ' ');

                treatment_name = treatment_name.toLowerCase().replace(/\b[a-z]/g, function( treatment_name ) {
                    return treatment_name.toUpperCase();
                });
            }

            dataLayer.push({
                'conversion' : {
                    'type' : 'enquiry',
                },
                'enquiry' : {
                    'clinic' : form.data.fields_by_key.clinic_name_1556457828981.value,
                    'treatment' : treatment_name
                },
                'user' : {
                    'type' : new_user
                },
                'event' : 'enquirySubmission'
            });
        }
    });
});
