jQuery(document).ready(function ($) {
    "use strict";

    var v = v8n();

    // Input fields array
    var inputFields = [
        // Billing
        'billing_first_name',
        'billing_last_name',
        // 'billing_company',
        'billing_address_1',
        // 'billing_address_2',
        'billing_city',
        // 'billing_state',
        'billing_postcode',
        'billing_phone',
        'billing_email',

        // Shipping
        'shipping_first_name',
        'shipping_last_name',
        // 'shipping_company',
        'shipping_address_1',
        // 'shipping_address_2',
        'shipping_city',
        // 'shipping_state',
        'shipping_postcode',

        // Registration form
        'first_name',
        'last_name',
        'email',
    ];

    var invalidHTML = '<svg xmlns="http://www.w3.org/2000/svg" class="crossmark" viewBox="0 0 52 52"><circle cx="26" cy="26" r="25" fill="none" class="crossmark__circle"/><path class="crossmark__cross" fill="#4a90d6" stroke="#fff" stroke-linejoin="round" d="M17.214 35.257l18.445-18.445M16.91 16.66l18.75 18.748"/></svg>';
    var validHTML   = '<svg xmlns="http://www.w3.org/2000/svg" class="checkmark" viewBox="0 0 52 52"><circle cx="26" cy="26" r="25" fill="none" class="checkmark__circle"/><path fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" class="checkmark__check"/></svg>';

    // Validation field helper method
    function validateField( $self ) {
        var val     =  $self.val(),
            name    = $self.attr('name');

        // Run field through validation check then style appropriately
        validate( $self, testField( name, val ) );
    }

    // Attach event handlers to inputFields
    for ( var i = 0; i < inputFields.length; i++ ) {
        $('input[name="' + inputFields[i] + '"]:not(:disabled):not([readonly])').on('keyup keydown', function(){
            validateField( $(this) );
        });
    }

    // Special case for registration password
    $('input#reg_password').on('keyup keydown', function(){
        validateField( $(this) );
    });

    // On submit of checkout form, check fields are validated first
    $('form[name="checkout"]').on('checkout_place_order', function(e) {
        var valid = true,
            shippingHidden = $('.checkout-details__shipping--hidden').length;

        // Touch all fields on submit of checkout form
        for ( var i = 0; i < inputFields.length; i++ ) {
            $('input[name="' + inputFields[i] + '"]').trigger('keyup');
        }

        // Check if fields are all valid
        for ( var i = 0; i < inputFields.length; i++ ) {
            var name = inputFields[i],
                $field = $('input[name="' + name + '"]');

            // Skip if shipping details are not editable
            if ( name.includes('shipping') && shippingHidden ) {
                continue;
            }

            // If field exists
            if ( $field.length ) {
                valid = testField( name, $field.val() );

                // Stop if not valid
                if ( !valid ) {
                    console.log('Invalid field: ', name);
                    break;
                }
            }
        }

        return valid;
    });

    // Register custom validation methods
    v8n.extend({ email, required, GBPostcode });

    /* -- Custom validation methods -- */

    // Taken from woocommerce php method
    function GBPostcode() {
        return function(to_check) {
    		// Permitted letters depend upon their position in the postcode.
    		// https://en.wikipedia.org/wiki/Postcodes_in_the_United_Kingdom#Validation.
    		var alpha1 = '[abcdefghijklmnoprstuwyz]'; // Character 1.
    		var alpha2 = '[abcdefghklmnopqrstuvwxy]'; // Character 2.
    		var alpha3 = '[abcdefghjkpstuw]';         // Character 3 == ABCDEFGHJKPSTUW.
    		var alpha4 = '[abehmnprvwxy]';            // Character 4 == ABEHMNPRVWXY.
    		var alpha5 = '[[abdefghjlnpqrstuwxyz]]';    // Character 5 != CIKMOV.

    		var pcexp = [];

    		// Expression for postcodes: AN NAA, ANN NAA, AAN NAA, and AANN NAA.
    		pcexp.push( /^([abcdefghijklmnoprstuwyz]{1}[abcdefghklmnopqrstuvwxy]{0,1}[0-9]{1,2})([0-9]{1}[abdefghjlnpqrstuwxyz]{2})$/ );

    		// Expression for postcodes: ANA NAA.
    		pcexp.push( /^([abcdefghijklmnoprstuwyz]{1}[0-9]{1}[abcdefghjkpstuw]{1})([0-9]{1}[abdefghjlnpqrstuwxyz]{2})$/ );

    		// Expression for postcodes: AANA NAA.
    		pcexp.push( /^([abcdefghijklmnoprstuwyz]{1}[abcdefghklmnopqrstuvwxy][0-9]{1}[abehmnprvwxy])([0-9]{1}[abdefghjlnpqrstuwxyz]{2})$/ );

    		// Exception for the special postcode GIR 0AA.
    		pcexp.push( /^(gir)(0aa)$/ );

    		// Standard BFPO numbers.
    		pcexp.push( /^(bfpo)([0-9]{1,4})$/ );

    		// c/o BFPO numbers.
    		pcexp.push( /^(bfpo)(c\/o[0-9]{1,3})$/ );

    		// Load up the string to check, converting into lowercase and removing spaces.
    		var postcode = to_check.toLowerCase();
    		postcode = postcode.replace( ' ', '' );

    		// Assume we are not going to find a valid postcode.
    		var valid = false;

    		// Check the string against the six types of postcodes.
    		for ( var i = 0; i < pcexp.length; i++ ) {
                var regexp = pcexp[i];

    			if ( postcode.match( regexp ) ) {
    				// Remember that we have found that the code is valid and break from loop.
    				valid = true;
    				break;
    			}
    		}

    		return valid;
        }
	}

    function email() {
        return function(value) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(value).toLowerCase());
        }
    }

    function required() {
        return function(value) {
            return value !== '';
        }
    }

    /* -- Helper methods -- */

    function validate( $elem, valid ) {

        if ( valid && !$elem.hasClass('valid') ) {
            $elem.siblings('svg.crossmark').remove();
            $elem.siblings('svg.checkmark').remove();

            $elem.removeClass('invalid').addClass('valid').after(validHTML);
        } else if ( !valid && !$elem.hasClass('invalid') ) {
            $elem.siblings('svg.crossmark').remove();
            $elem.siblings('svg.checkmark').remove();

            $elem.removeClass('valid').addClass('invalid').after(invalidHTML);
        }
    }

    function testField( name, value ) {
        var test = true;

        if (
            'billing_first_name' == name ||
            'billing_last_name' == name ||
            'billing_address_1' == name ||
            'shipping_first_name' == name ||
            'shipping_last_name' == name ||
            'shipping_address_1' == name ||
            'first_name' == name ||
            'last_name' == name ||
            'password' == name
        ) {
            test = v.string().required().test(value);
        } else if (
            'billing_city' == name ||
            'shipping_city' == name
        ) {
            test = v.string().required().minLength(2).test(value);
        } else if (
            'billing_postcode' == name ||
            'shipping_postcode' == name
        ) {
            test = v.GBPostcode().test(value);
        } else if (
            'billing_phone' == name
        ) {
            test = v.string().required().minLength(7).test(value);
        } else if (
            'billing_email' == name ||
            'email' == name
        ) {
            test = v.email().test(value);
        }

        return test;
    }
});
