jQuery(document).ready(function ($) {
    "use strict";

    $('[data-faqs-heading]').on('click', function(e) {

        var target = $(this).data('faqs-heading');

        $('[data-faqs]').find($('[data-faqs-heading]')).not('[data-faqs-heading="' + target + '"]').removeClass('open');
        $('[data-faqs]').find($('[data-faqs-content]')).not('[data-faqs-content="' + target + '"]').removeClass('open');

        $(this).toggleClass('open');
        $(this).parent().find($('[data-faqs-content="' + target + '"]')).toggleClass('open');

    });

});
