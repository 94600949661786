// Resizing the select filters

jQuery(document).ready(function ($) {
    "use strict";

    $('#area-of-expertise-select').change(function(){
       $("#consultant-filters-001__area-option-width").html($('#area-of-expertise-select option:selected').text());
       $(this).width($("#consultant-filters-001__area-select-width").width());
    });

    $('#clinic-select').change(function(){
       $("#consultant-filters-001__clinic-option-width").html($('#clinic-select option:selected').text());
       $(this).width($("#consultant-filters-001__clinic-select-width").width());
    });

});
