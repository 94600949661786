jQuery(document).ready(function ($) {
    "use strict";

	$('.full-width-faqs-001__faq-title').on('click', function(e) {
        $(this).next('.full-width-faqs-001__content').slideToggle('200');
        $(this).children('.full-width-faqs-001__plus-icon').toggleClass('full-width-faqs-001__plus-icon--active');
        $(this).toggleClass('active');
    });

    $('.full-width-faqs-001__content').first().css('display', 'block');
    $('.full-width-faqs-001__faq-title').first().children().addClass('full-width-faqs-001__plus-icon--active');
    $('.full-width-faqs-001__faq-title').first().addClass('active');

});
