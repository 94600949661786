jQuery(document).ready(function ($) {
    "use strict";

    if ( $('.pricing-tabs-001').length ) {
        var offset = $('.pricing-tabs-001').offset().top;
        $('.pricing-tabs-001__tabs--bottom [data-link]').on('click', function(){
            var target = $(this).data('link');

            // Add active class to the top tabs
            $('.pricing-tabs-001__tabs--top [data-link="' + target + '"]').addClass('is-active').siblings().removeClass('is-active');

            // Scroll to the top
            $('html').animate({
                scrollTop: offset
            }, 1000);
        });

        $('.pricing-tabs-001__tabs--top [data-link]').on('click', function(){
            var target = $(this).data('link');
            
            $('.pricing-tabs-001__tabs--bottom [data-link="' + target + '"]').addClass('is-active').siblings().removeClass('is-active');
        });
    }
});
