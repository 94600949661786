jQuery(document).ready(function ($) {
    "use strict";

	$('.panel-heading').on('click', function(e) {

  $(this).closest('a').attr('aria-expanded', function (i, attr) {
    return attr == 'true' ? 'false' : 'true';

});


    });

});
