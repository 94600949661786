jQuery(document).ready(function ($) {
    "use strict";

    /**
     * Obscure shipping details when 'use same as billing' is selected
     */
    var shipping_same_as_billing_classes = function () {
        var input = $('#ship-to-different-address-checkbox');
        var container =  $(input).closest('.checkout-details').find('.checkout-details__shipping');
        if($(input).is(':checked'))
            container.removeClass('checkout-details__shipping--hidden');
        else
            container.addClass('checkout-details__shipping--hidden');
    }

    shipping_same_as_billing_classes();
    $('body').on('change', '#ship-to-different-address-checkbox', shipping_same_as_billing_classes);

    /**
     * Custom ajax cart code
     */
    $(document).on('submit', '.product-cart', function(e) {

        // AJAX add to cart request
        var $form = $(this);
        // Turn response into key value pairs
        var data = {};
        $.each(​$form.serializeArray(), function() {
            data[this.name] = this.value;
        })​;

        if(typeof data['product_id'] === 'undefined' || !data['product_id']) {
            return true;
        }

        e.preventDefault();

        $form.removeClass( 'added' );
        $form.addClass( 'loading' );

        // Trigger event
        $( document.body ).trigger( 'adding_to_cart', [ $form, data ] );

        // Ajax action
        $.post( wc_add_to_cart_params.wc_ajax_url.toString().replace( '%%endpoint%%', 'get_refreshed_fragments' ), data, function( response ) {

            if ( ! response ) {
                return;
            }

            var this_page = window.location.toString();

            if ( response.error && response.product_url ) {
                window.location = response.product_url;
                return;
            }

            // Redirect to cart option
            if ( wc_add_to_cart_params.cart_redirect_after_add === 'yes' ) {

                window.location = wc_add_to_cart_params.cart_url;
                return;

            } else {

                $form.removeClass( 'loading' );

                var fragments = response.fragments;
                var cart_hash = response.cart_hash;

                // Block fragments class
                if ( fragments ) {
                    $.each( fragments, function( key ) {
                        $( key ).addClass( 'updating' );
                    });
                }

                // Block widgets and fragments
                $( '.shop_table.cart, .updating, .cart_totals' ).fadeTo( '400', '0.6' ).block({
                    message: null,
                    overlayCSS: {
                        opacity: 0.6
                    }
                });

                // Changes button classes
                $form.addClass( 'added' );

                // Replace fragments
                if ( fragments ) {
                    $.each( fragments, function( key, value ) {
                        $( key ).replaceWith( value );
                    });
                }

                // Unblock
                $( '.widget_shopping_cart, .updating' ).stop( true ).css( 'opacity', '1' ).unblock();

                // Cart page elements
                $( '.shop_table.cart' ).load( this_page + ' .shop_table.cart:eq(0) > *', function() {

                    $( '.shop_table.cart' ).stop( true ).css( 'opacity', '1' ).unblock();

                    $( document.body ).trigger( 'cart_page_refreshed' );
                });

                $( '.cart_totals' ).load( this_page + ' .cart_totals:eq(0) > *', function() {
                    $( '.cart_totals' ).stop( true ).css( 'opacity', '1' ).unblock();
                });

                // Trigger event so themes can refresh other areas
                $( document.body ).trigger( 'added_to_cart', [ fragments, cart_hash, $form ] );
            }
        });

        return false;
    });

    /**
     * Show cart popups on successful addition
     */
    $(document).on('added_to_cart', 'body', function(event, fragments, cart_hash, $form) {
        $('#mini-cart').css({'top': $('.main-header').outerHeight()});
        $('#mini-cart').toggleClass('is-active');
    });

});
