(function($){
    "use strict";

    /**
     * Simple tab system v1.1
     * @return jQuery Object    Element (chaining)
     */
    $.fn.tabs = function () {

        $(this).each(function () {

            // Remove any elements that shouldn't show when active
            $(this).find('[data-tab-hide]').hide();

            // Find the closest tab navigation and add active to first
            $(this).find('[data-tab-navigation] a:first-child').addClass('is-active');

            // Hide all tab contents except first
            $(this).find('[data-tab-content] > *:not(:first-child)').hide();

            // Setup on click handler for tabs
            $(this).find('[data-tab-navigation] > *').on('click', function (e) {
                e.preventDefault();

                // Get the ID of this tabs target
                var target = $(this).data('link');

                // Remove active from all tabs and add to this one
                $(this).siblings().removeClass('is-active');
                $(this).addClass('is-active');

                // Hide all tab contents and show this one
                $(this).closest('[data-tabs]').find('[data-tab-content] > *').hide();
                $(this).closest('[data-tabs]').find('[data-tab-content] [data-content="' + target + '"]').show();
            });
        });

        // Return the jQuery element
        return this;
    };

})(jQuery);
