jQuery(document).ready(function ($) {
    "use strict";

    // Enable smooth anchor link scrolling
    // on all link elements (href="#...")

    var $table = $('.price-table-001__table-mobile');
    // float the headers
    $table.floatThead({top:106, zIndex:1, position: 'fixed'});

    $table.floatThead('reflow');

    $.localScroll({target: 'body', offset: -$('.main-header').outerHeight() });

    $('[data-scrollto]').on('click', function(e){
        if ( $(e.target).is('a') || $(e.target).is('button') ) return;
        var target = $(this).data('scrollto');

        $('html, body').animate({
            scrollTop: $(target).offset().top - $('.main-header').outerHeight()
        }, 1000);
    });

    $('[data-link]').on('click', function (e) {
        e.preventDefault();
        var did = $(this).data('link');
        $('div' + did).toggleClass('is-active');
        $(this).toggleClass('is-active');
    });


    if ( $(window).width() > 1200 && $(window).height() > 650 ) {
        if ( $(this).scrollTop() > 0 ) {
            $('.main-header').addClass('scrolled');
            $('body').addClass('scrolled');
        }
        $(window).on('scroll', function(){
            if ( $(this).scrollTop() > 0 ) {
                $('.main-header').addClass('scrolled');
                $('body').addClass('scrolled');
                $('.main-header').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function() {
                    $('body').css({'paddingTop': $('.main-header').outerHeight()});
                });
            } else {
                $('.main-header').removeClass('scrolled');
                $('body').removeClass('scrolled');
                $('.main-header').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function() {
                    $('body').css({'paddingTop': $('.main-header').outerHeight()});
                });
            }
        });
    }

    if ( !Modernizr.objectfit ) {
    $('img[srcset]').each(function() {
        var $container = $(this).parent(),
            imgUrl = $(this).prop('srcset');

        if ( imgUrl ) {
            $container
                .css( 'backgroundImage', 'url(' + imgUrl + ')' )
                .addClass( 'compat-object-fit' );
        }
    });
}

    

    // Tab functionality
    $('[data-tabs]').tabs();

    // Opens Magnific Popup's, using the data attribute to group items into a gallery,
    // For dynamic type to work, you will need to add the class mfp-TYPE to all elements.
    // Use mfp-image for images, mfp-iframe for videos, and mfp-inline for HTML.
    /* Look at this further, can be more efficient. */
    $('[data-mfp]').on('click', function (e) {
        e.preventDefault();

        $('[data-mfp]').magnificPopup({
            gallery: {
                enabled: true
            }
        }).magnificPopup('open');
    });

    // Magnific popup for galleries
    $('.magnific-gallery').magnificPopup({
        type: 'image',
        gallery:{
            enabled:true
        }
    });

    // Fade in mobile footer buttons on scroll

    if ($(window).width() < 992) {
        $('.main-footer__mobile-buttons').hide();
        $(window).scroll(function() {
            if ($(document).scrollTop() > 100) {
                $('.main-footer__mobile-buttons').fadeIn('slow');
            }
            else {
                $('.main-footer__mobile-buttons').fadeOut('slow');
            }
        });
    }
    else {
        $('.main-footer__mobile-buttons').hide();
    }

    // Object fit modernizr for IE
    if ( !Modernizr.objectfit ) {
        $('img.object-fit').each(function() {
            var $container = $(this).parent(),
                imgUrl = $(this).prop('src');

            if ( imgUrl ) {
                $container
                    .css( 'backgroundImage', 'url(' + imgUrl + ')' )
                    .addClass( 'compat-object-fit' );
            }
        });
    }

});


delayFunction(function(){

    if ( jQuery('[data-trustpilot-widget]').length ) {
        jQuery.getScript('//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js');
    }

    ga(function() {
        var trackers = ga.getAll();

        // If we have some tracking codes, loop over these
        if ( trackers ) {

            // Variables to hold length and looping count
            var i, len;

            // Whilst we have tracking codes, iterate over these
            for ( i = 0, len = trackers.length; i < len; i += 1 ) {
                // If our tracking code is the main UA tracker we need to pull from, proceed
                if ( trackers[i].get('trackingId') === 'UA-15391650-1' ) {

                    // If we have a ninja form field for this to go into available
                    if ( jQuery('#nf-field-54').length ) {
                    // Set the value of this hidden field to the Client ID
                    jQuery('#nf-field-54').val( trackers[i].get('clientId') );
                    }
                }
            }
        }
    });

});
