(function () {
    "use strict";

    /**
     * Set elements to monitor input values
     * @return jQuery Object    Element (chaining)
     */
    jQuery.fn.stickyPlaceholder = function () {

        var // Get the value of the inner input
            val = jQuery(this).find('input:not(:disabled)').val(),
            // Store `this` so it's available within timer
            self = this;

        // If a dropdown is found use its value instead
        if (jQuery(this).find('select:not(:disabled):not(.disabled)').length) {
            val = jQuery(this).find('option:selected').val();
        }

        // If there's a value add corresponding class
        if (val) {
            jQuery(this).addClass('sticky-placeholder--has-value');
        } else {
            jQuery(this).removeClass('sticky-placeholder--has-value');
        }

        // Recalculate on full load
        jQuery(window).load(function () {
            // Re-run this function
            jQuery(self).stickyPlaceholder();
        });

        // Recalculate when inputs change
        jQuery(this).find(':input').on('change', function () {
            // Re-run this function
            jQuery(self).stickyPlaceholder();
        });

        // Return the jQuery element
        return this;
    };

}());
