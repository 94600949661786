jQuery(document).ready(function ($) {
    "use strict";

    if ($(window).width() < 1180) {
        $('.featured-conditions-treatments-001__left-col-heading').on('click', function() {
            $('.featured-conditions-treatments-001__list-wrapper').slideToggle('200');
            $('.featured-conditions-treatments-001__left-col-heading').children().toggleClass('left-col-heading__plus-icon left-col-heading__plus-icon--active');
            $('.left-col-heading__plus-icon--active').text('-');
            $('.left-col-heading__plus-icon').text('+');
        });

        $('.featured-conditions-treatments-001__right-col-heading').on('click', function() {
            $('.featured-conditions-treatments-001__right-col-row').slideToggle('200');
            $('.featured-conditions-treatments-001__right-col-heading').children().toggleClass('right-col-heading__plus-icon right-col-heading__plus-icon--active');
            $('.right-col-heading__plus-icon--active').text('-');
            $('.right-col-heading__plus-icon').text('+');
        });

        $('.treatments-heading').on('click', function() {
            $(this).next().slideToggle('200');
            $(this).children('.treatments-heading__dropdown-arrow').toggleClass('treatments-heading__dropdown-arrow--active');
        });
    }

});
