jQuery(document).ready(function ($) {
  ("use strict");

  //Get all clinics and treatments.
  getClinicsandTreaments();

  // Checkout page. Remove omni plugin class.
  if ($("#payment .payment_method_omni_finance").lenght > 0) {
    $("#curPrice").next().removeClass();
    $("#ocrf_widget").find("span").remove();
  }

  // Restore button text and icon when paying by card.
  $("#payment_method_stripe").on("click", function () {
    // Check if the radio button is now selected
    if ($(this).prop("checked")) {
      var $text = $("#place_order").text();
      if ($text === "Pay by Finance") {
        // Create the SVG element
        var svgElement =
          '<svg role="presentation" class="checkout-payment__icon"><title>Secure</title><use xlink:href="#shield"></use></svg>';

        // Create the span element
        var spanElement =
          '<span class="checkout-payment__button-label">Checkout Securely</span>';

        // Create the button element with the SVG and span elements
        var buttonElement =
          '<button class="btn checkout-payment__button" name="woocommerce_checkout_place_order" id="place_order">' +
          svgElement +
          " " +
          spanElement +
          "</button";

        // Replace the existing button with the new button element
        $("#place_order").replaceWith(buttonElement);
      }
    }
  });

  // Button to redirect to Pabau. Appears on the local landers pages.
  $(document).on("click", "#click-book-button", function (e) {
    var $data = {
      event: "stepInteraction",
      formStep: "book consultation",
      stepAction: "CTA click",
      locationSelected: $(".ga-clinic-name").text(),
      serviceSelected: $(".ga-element-name").text(),
    };
    window.dataLayer = window.dataLayer || [];
    dataLayer.push($data);
    writeLogs($data);
  });

  // Google tag manager when clicking treatment blocks on local landers.
  $(document).on("click", "#location_url", function (e) {
    var select_find = $(this).parent().siblings(".ui-widget").find(".select2");
    var selectedOptionLabel = select_find.find("option:selected").text();
    var $data = {
      event: "stepInteraction",
      formStep: "book consultation",
      stepAction: "CTA click",
      locationSelected: selectedOptionLabel,
      serviceSelected: $(".ga-element-name").text(),
    };
    dataLayer.push($data);
    writeLogs($data);
  });

  // Pabau treatments blocks functionality .
  $("select[name='location']").select2();
  $("select[name='location']").change(function () {
    var selectValue = $(this).val();
    var bookButton = $(this).parent().siblings().find("#location_url");
    if (selectValue !== "Location") {
      var service_id = $(this).parent().siblings(".d-none").text();
      appendURL(service_id, bookButton, selectValue);
    } else {
      bookButton.addClass("disabled");
    }
  });

  // Google tag manager when clicking the button fro Pabau widget.
  $(document).on("click", "#book-button", function (e) {
    var $data = {
      event: "stepInteraction",
      formStep: "book consultation",
      stepAction: "CTA click",
    };

    if ($('select[name="treatmentm"]').length > 0) {
      $data.locationSelected = $('select[name="clinic"]')
        .find("option:selected")
        .text();
      $data.serviceSelected = $('select[name="treatmentm"]')
        .find("option:selected")
        .text();
    } else {
      var selectedOptionLabel = $('select[name="clinic"]')
        .find("option:selected")
        .text();
      var firstOptionLabel = $('select[name="clinic"]')
        .find("option:first")
        .text();

      if (firstOptionLabel === "Please select a treatment") {
        $data.locationSelected = $(".ga-element-name").text();
        $data.serviceSelected = selectedOptionLabel;
      } else if (firstOptionLabel === "Please select a clinic") {
        $data.locationSelected = selectedOptionLabel;
        $data.serviceSelected = $(".ga-element-name").text();
      }
    }

    window.dataLayer = window.dataLayer || [];
    dataLayer.push($data);
    writeLogs($data);
  });

  $(window).on("beforeunload", function () {
    $('select[name="treatment"]').val(1);
    $("[id^='book-butto']").addClass("disabled");
  });

  $('select[name="treatment"]').on("change", function () {
    removeElements();
    getClinics($('select[name="treatment"] option:selected'));
  });

  /**
   * Get clinic list for the selected treatment.
   * @param {dom selector field} $selector
   *   Select list name with selected option.
   */
  function getClinics($selector) {
    var $result = $.ajax({
      type: "POST",
      url: "/wp-content/themes/skin/getClinicsForTreatments.php",
      data: { treatment_id: $selector.val(), treatment_name: $selector.text() },
      success: pabauTreatmentsCallback,
    });
  }

  /**
   * Function to handle the treatment dropdown change event
   * @param {int} selectValue
   * @param {dom select field} clinicSelectField
   */
  function handleTreatmentDropdownChange(selectValue, clinicSelectField) {
    writeSelectOptions(
      selectValue,
      "treatment_id",
      "clinic_id",
      clinicSelectField
    );
    var selectedOptionText = jQuery(".treatment-choise")
      .find("option:selected")
      .text();
    if (selectValue != 1) {
      sendGtmEvent("treatment", "treatment dropdown", selectedOptionText);
    }
  }

  jQuery('select[name="treatmentm"]').on("change", function () {
    var selectValue = jQuery(this).val();
    var clinicSelectField = jQuery(".clinic-choise");
    var selectedIndex = this.selectedIndex;
    var selectedOption = $(this).find('option').eq(selectedIndex);
    var hiddenValue = selectedOption.data('hidden');
    updateBookButtonUrl(
      jQuery(".clinic-choise").val(),
      jQuery(".treatment-choise"),
      hiddenValue
    );
    handleTreatmentDropdownChange(selectValue, clinicSelectField);
  });

  // Remove clinic list if already exists.
  function removeElements() {
    $("[id^='book-butto']").addClass("disabled");
  }

  /**
   * Get all Pabau treatments/clinics.
   */
  function getClinicsandTreaments() {
    var $result = $.ajax({
      type: "POST",
      url: "/wp-content/themes/skin/templates/blocks/pabau-dropdowns/getClinicOrTreatment.php",
      data: {},
      success: pabauClinicTreatmentListCallback,
    });
  }

  // Ajax callback with Pabau clinic and Treatments.
  function pabauClinicTreatmentListCallback(response) {
    localStorage.setItem("pabauClinicTreatmentsObject", response);
  }

  // Ajax callback with clinic list.
  function pabauTreatmentsCallback(response) {
    const obj = JSON.parse(response);
    var clinics = obj[0].clinics;
    var pabau_id = obj[0].pabau_id;
    //$('.clicked-content-instance').find('.booking-system__button').remove();
    var output = "";

    for (let i = 0; i < clinics.length; i++) {
      output +=
        '<option id="' +
        pabau_id[0].service_id +
        '" value="' +
        clinics[i].location_id +
        '">';
      output += clinics[i].name;
      output += "</option>";
    }

    $(".clinic-choise").append(output);
  }

  /**
   * Function to filter and display select options based on specified criteria.
   * @param {int} elementId - The selected value in the dropdown.
   * @param {string} field1 - The first key used to search within the JSON data array.
   * @param {string} field2 - The second key used to search within the JSON data array.
   * @param {element} selectField - The jQuery select field element where options will be modified.
   */
  function writeSelectOptions(elementId, field1, field2, selectField) {
    var data = localStorage.getItem("pabauClinicTreatmentsObject");
    var jsonData = JSON.parse(data)[0];

    var filteredData = jsonData.filter(function (item) {
      return item[field1] === elementId;
    });

    if (elementId != 1) {
      // Loop through each option element
      selectField.find("option").each(function () {
        var option = $(this); // Convert the option element to a jQuery object
        var optionValue = option.val();
        var optionFound = filteredData.some(function (item) {
          return item[field2] === optionValue;
        });

        // If the option is not found, wrap it in a span and hide the parent span
        if (!optionFound && optionValue != 1) {
          option.filter(":not(span > option)").wrap("<span>").parent().hide();
        } else {
          // If the option is found, unwrap it (if it was previously wrapped)
          option.filter("span > option").unwrap();
        }
      });
      isHiddenGroup(selectField.parent());
    }

    // Show all treatments.
    else {
      selectField.find("span").find("optgroup").unwrap();
      selectField
        .find("option")
        .filter(function () {
          return $(this).closest("span").length > 0;
        })
        .unwrap();
    }
  }

  /**
   * Hide optgroup if it doesn't have children.
   * @param {selector} element
   */
  function isHiddenGroup(element) {
    const $selectField = element;

    $selectField.find("optgroup").each(function () {
      const $optgroup = $(this);
      const $children = $optgroup.children();
      let allChildrenHidden = true;

      $children.each(function () {
        if (!$(this).is("span")) {
          allChildrenHidden = false;
          return false; // Exit the loop if a visible child is found
        }
      });

      if (allChildrenHidden) {
        // Check if the optgroup is already wrapped within a span
        if (!$optgroup.parent().is("span")) {
          $optgroup.wrap("<span>").parent().hide();
        }
      } else {
        // Check if the optgroup is wrapped within a span
        if ($optgroup.parent().is("span")) {
          $optgroup.unwrap();
        }
      }
    });
  }

  /**
   * Function to update the Book A Consultation button's URL and enable/disable it
   * @param {element select value} selectValue
   * @param {element dom selector} selectField
   */
  function updateBookButtonUrl(selectValue, selectField, staffUrl = null) {
    var service_id = selectField.find("option:selected").attr("id");
    var bookButton = selectField.parent().next().next().find("#book-button");
    if(bookButton.length === 0) {
      var bookButton = selectField.parent().siblings().find("#book-button-LHL");
    }

    if (selectValue != 1 && service_id) {
      appendURL(service_id, bookButton, selectValue, staffUrl);
      console.log(bookButton) ;
    }
    //This a treatment or promotion page.
    else if (selectField.length === 0 && selectValue != 1) {
      service_id = $(".clinic-choise").find("option:selected").attr("id");
      var bookButton = $(".clinic-choise").parent().next().find("#book-button");
      appendURL(service_id, bookButton, selectValue, staffUrl);
    } else {
      bookButton.addClass("disabled");
    }
  }

  /**
   * Function to append url to an existing button and remove disbaled class.
   * @param {int} service_id
   * @param {dom selector} bookButton
   * @param {element value} selectValue
   */
  function appendURL(service_id, bookButton, selectValue, staffUrl = null) {
    var staff = '';
    if(staffUrl == 1) {
      staff = '&staff=Any';
    }

    var hrefV =
      "https://onlinebookings.sknclinics.co.uk/bookings.php?compid=11613&location_id=" +
      selectValue +
      "&service=" +
      service_id +
      staff;
    bookButton.attr("href", hrefV);
    bookButton.removeClass("disabled");
  }

  /**
   * Function to handle Google Tag Manager events
   * @param {string} formStep
   * @param {string} stepAction
   * @param {string} actionDetail
   */
  function sendGtmEvent(formStep, stepAction, actionDetail) {
    var data = {
      event: "stepInteraction",
      formStep: formStep,
      stepAction: stepAction,
      actionDetail: actionDetail,
    };
    window.dataLayer = window.dataLayer || [];
    dataLayer.push(data);
    writeLogs(data);
  }

  //Append Pabau url to Book A Consultation button.
  jQuery(document).on("change", ".clinic-choise", function () {
    var selectValue = jQuery(this).val();
    var selectField = jQuery(".treatment-choise");

    // Two dropdowns exists.
    if (selectField.length > 0) {
      //Show/hide treatments based on the clinic selection
      writeSelectOptions(selectValue, "clinic_id", "treatment_id", selectField);
    }

    // A clinic was selected.
    if (selectValue != 1) {
      if(jQuery('select[name="treatmentm"]').length > 0) {
        var selectedOption = jQuery('select[name="treatmentm"]').find('option:selected');
      } else {
        var selectValue = jQuery(this).val();
        var selectedIndex = this.selectedIndex;
        var selectedOption = $(this).find('option').eq(selectedIndex);
      }
      var hiddenValue = selectedOption.data('hidden');


      updateBookButtonUrl(selectValue, selectField, hiddenValue);


      var firstOptionLabel = jQuery(this).find("option:first").text();
      if (firstOptionLabel === "Please select a treatment") {
        sendGtmEvent(
          "treatment",
          "treatment dropdown",
          jQuery(this).find("option:selected").text()
        );
      } else if (firstOptionLabel === "Please select a clinic") {
        sendGtmEvent(
          "location",
          "location dropdown",
          jQuery(this).find("option:selected").text()
        );
      }
    } else {
      jQuery(this).parent().next().find("#book-button").addClass("disabled");
    }
  });

//Append Pabau url to Book A Consultation button.
jQuery(document).on("change", ".clinic-choise-lhl", function () {
  var selectValue = jQuery(this).val();
  var selectField = jQuery(this);

  // A clinic was selected.
  if (selectValue != 1) {
    if(jQuery('select[name="treatmentm"]').length > 0) {
      var selectedOption = jQuery('select[name="treatmentm"]').find('option:selected');
    } else {
      var selectValue = jQuery(this).val();
      var selectedIndex = this.selectedIndex;
      var selectedOption = $(this).find('option').eq(selectedIndex);
    }
    var hiddenValue = selectedOption.data('hidden');


    updateBookButtonUrl(selectValue, selectField, hiddenValue);


    var firstOptionLabel = jQuery(this).find("option:first").text();
   if (firstOptionLabel === "Please select a clinic") {
      sendGtmEvent(
        "location",
        "location dropdown",
        jQuery(this).find("option:selected").text()
      );
    }
  } else {
    jQuery(this).parent().next().find("#book-button").addClass("disabled");
  }
});



  // Write logs on the server
  /**
   *
   * @param {array} dataLayer
   *   Array that contains all data pushed to datalayer.
   */
  function writeLogs(dataLayer) {
    $.ajax({
      type: "POST",
      url: "/wp-json/wp/v2/custom-logger", // Change to your server-side script URL
      data: { newData: dataLayer }, // Send data to the server
      success: function (response) {},
      error: function (error) {},
    });
  }
});
